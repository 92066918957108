export class ApiCaller {
    constructor(storage, controller) {
        this._controller = controller;
        this._listners = [];
        this._storage = storage;
        this._isLocalDebug = true;
    }

    addEventListener(obj) {
        this._listners.push(obj);
    }

    notify(event, end_point, data, method) {
        if(typeof data === "undefined") {
            data = {};
        }

        const len = this._listners.length;
        for(let ii=0;ii<len;ii++) {
            this._listners[ii].notify(event, end_point, data, method);
        }
    }

    saveUserId(userId) {

    }


    getUserInfo() {

        this.get(ApiCaller.EndPoints.GetUserInfo);
    }

    postMaestroCard(params) {

        // userInfoのmaestroCardの保存有無でAPIを分ける.
        let userId = this._storage.getUserId();
        if(userId !== "" && this._controller._userInfo.maestroCard != null) {
            this.put(ApiCaller.EndPoints.PostMaestroCard + userId + "/", params);
        }else{
            this.post(ApiCaller.EndPoints.PostMaestroCard, params);
        }
    }


    postWebTryOn(itemsColors_id) {
        let params = {};
        params.itemsColors_id = itemsColors_id;
        this.post(ApiCaller.EndPoints.WebTryOn, params);

    }

    deleteWebTryOn(itemsColors_id) {
        let params = {};
        params.itemsColors_id = itemsColors_id;
        const userId = this._storage.getUserId();
        this.delete(ApiCaller.EndPoints.WebTryOn + userId + "/", params);
    }


    getRecommendedItems() {
        this.get(ApiCaller.EndPoints.RecommendedItems);
    }

    put(end_point, params) {
        const _this = this;
        if(typeof params === "undefined") {
            params = {};
        }

        // API呼び出し.
        $.ajax({
            type: 'PUT',
            url: ApiCaller.EndPointBase + end_point,
            xhrFields: {
                withCredentials: true
            },
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify(params),
            beforeSend: function(xhr){
                xhr.withCredentials = true;
                xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            },
        }).then(
            // 成功時のコールバック
            function (data) {
                _this.notify(ApiCaller.Event.ApiResponse, end_point, data, "put")
            },
            // 失敗時のコールバック
            function () {
                _this.notify(ApiCaller.Event.ApiResponseError, end_point, {}, "put")
            }
        );
    }

    post(end_point, params) {
        const _this = this;
        if(typeof params === "undefined") {
            params = {};
        }

        // API呼び出し.
        $.ajax({
            type: 'POST',
            url: ApiCaller.EndPointBase + end_point,
            xhrFields: {
                withCredentials: true
            },
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify(params),
            beforeSend: function(xhr){
                xhr.withCredentials = true;
                xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            },
        }).then(
            // 成功時のコールバック
            function (data) {
                _this.notify(ApiCaller.Event.ApiResponse, end_point, data, "post")
            },
            // 失敗時のコールバック
            function (data) {
                _this.notify(ApiCaller.Event.ApiResponseError, end_point, data.responseJSON, "post")
            }
        );
    }

    get(end_point, params) {

        const _this = this;
        if(typeof params === "undefined") {
            params = {};
        }

        // API呼び出し.
        $.ajax({
            type: 'GET',
            url: ApiCaller.EndPointBase + end_point,
            xhrFields: {
                withCredentials: true
            },
            contentType: 'application/json',
            dataType: 'json',
            data: params,
            beforeSend: function(xhr){
                xhr.withCredentials = true;
                xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            },
        }).then(
            // 成功時のコールバック
            function (data) {
                _this.notify(ApiCaller.Event.ApiResponse, end_point, data, "get")
            },
            // 失敗時のコールバック
            function () {
                _this.notify(ApiCaller.Event.ApiResponseError, end_point, {}, "get")
            }
        );
    }


    delete(end_point, params) {

        const _this = this;
        if(typeof params === "undefined") {
            params = {};
        }

        // API呼び出し.
        $.ajax({
            type: 'DELETE',
            url: ApiCaller.EndPointBase + end_point,
            xhrFields: {
                withCredentials: true
            },
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify(params),
            beforeSend: function(xhr){
                xhr.withCredentials = true;
                xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            },
        }).then(
            // 成功時のコールバック
            function (data) {
                _this.notify(ApiCaller.Event.ApiResponse, end_point, data, "delete")
            },
            // 失敗時のコールバック
            function () {
                _this.notify(ApiCaller.Event.ApiResponseError, end_point, {}, "delete")
            }
        );
    }

    //////////////////////////////////////////////////////////////////////////
    // APIではないが便宜上APIとして扱う.
    //////////////////////////////////////////////////////////////////////////
    getStorePrefectureList(prefJsonType) {

        const _this = this;
        let end_point = ApiCaller.EndPoints.StorePrefJson;
        if(prefJsonType === 2) {
          end_point = ApiCaller.EndPoints.StorePrefJsonReserved;
        }else if(prefJsonType === 1) {
          end_point = ApiCaller.EndPoints.StorePrefJsonContact;
        }
        let url = ApiCaller.EndPointBase + end_point;

        if(this._isLocalDebug) {
           url =  end_point;
        }

        $.ajax({
            type: 'GET',
            url:url,
            contentType: 'application/json',
            dataType: 'json',

        }).then(
            function(data) {
                _this.notify(ApiCaller.Event.ApiResponse, end_point, data, "get")
            },
            function() {
                _this.notify(ApiCaller.Event.ApiResponseError, end_point, {}, "get")
            }
        )
    }


    getProductSearchCondition() {

        const _this = this;
        const end_point = ApiCaller.EndPoints.ProductSearchConditionJson;
        let url = ApiCaller.EndPointBase + end_point;

        if(this._isLocalDebug) {
           url =  end_point;
        }

        $.ajax({
            type: 'GET',
            url:url,
            contentType: 'application/json',
            dataType: 'json',

        }).then(
            function(data) {
                _this.notify(ApiCaller.Event.ApiResponse, end_point, data, "get")
            },
            function() {
                _this.notify(ApiCaller.Event.ApiResponseError, end_point, {}, "get")
            }
        )
    }


    getMaestroCardQuestion() {

        const _this = this;
        const end_point = ApiCaller.EndPoints.MaestroCardQuestionJson;
        let url = ApiCaller.EndPointBase + end_point;

        if(this._isLocalDebug) {
           url =  end_point;
        }


        $.ajax({
            type: 'GET',
            url:url,
            contentType: 'application/json',
            dataType: 'json',

        }).then(
            function(data) {
                _this.notify(ApiCaller.Event.ApiResponse, end_point, data, "get")
            },
            function() {
                _this.notify(ApiCaller.Event.ApiResponseError, end_point, {}, "get")
            }
        )
    }
}

ApiCaller.EndPointBase = 'https://www.vision-megane.co.jp';
if(location.href.match(/dev\.vision-megane/) !== null || location.href.match(/localhost/) !== null) {
    ApiCaller.EndPointBase = 'https://dev.vision-megane.co.jp';
}else if(location.href.match(/stg\.vision-megane/) !== null) {
    ApiCaller.EndPointBase = 'https://stg.vision-megane.co.jp';
}else if(location.href.match(/\/\/vision-megane/) !== null) {
    ApiCaller.EndPointBase = 'https://vision-megane.co.jp';
}

ApiCaller.Event = {};
ApiCaller.Event.ApiResponse         = 1;
ApiCaller.Event.ApiResponseError    = 2;

ApiCaller.EndPoints = {};
ApiCaller.EndPoints.GetUserInfo = '/api/v1/self/';
ApiCaller.EndPoints.PostMaestroCard = '/api/v1/maestro-cards/';
ApiCaller.EndPoints.StorePrefJson = '/img/sys/pref.json';
ApiCaller.EndPoints.StorePrefJsonReserved = '/img/sys/prefReserved.json';
ApiCaller.EndPoints.StorePrefJsonContact = '/img/sys/prefContact.json';
ApiCaller.EndPoints.ProductSearchConditionJson = '/img/sys/items.json';
ApiCaller.EndPoints.MaestroCardQuestionJson    = '/img/sys/maestro-card-question.json';
ApiCaller.EndPoints.StoreSearch = '/api/v1/shops/?store';
ApiCaller.EndPoints.ReserveStoreSearch = '/api/v1/shops/?reserve';
ApiCaller.EndPoints.WebTryOn = '/api/v1/try-on-list/';
ApiCaller.EndPoints.RecommendedItems = '/api/v1/recommended-items/';
